import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header/Header';
import Initial from './components/Initial/Initial';
import AboutMe from './components/AboutMe/AboutMe';
import Experiences from './components/Experiences/Experiences';
import Projects from './components/Projects/Projects';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import MyProvider from './context/MyProvider';

function App() {
  return (
    <MyProvider>
      <Header />
      <Initial />
      <AboutMe />
      <Experiences />
      <Projects />
      <Contact />
      <Footer />
      <ToastContainer
        position="top-right"
        closeOnClick
        draggable
        theme="dark"
        style={{ background: '#333', color: '#fff' }}
      />
      <ToastContainer />
    </MyProvider>
  );
}

export default App;
