import React, { useState, useMemo } from 'react';
import propTypes from 'prop-types';

import AppContext from './AppContext';

function MyProvider({ children }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const value = useMemo(
    () => ({
      activeMenu,
      setActiveMenu,
    }),
    [activeMenu, setActiveMenu],
  );

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

export default MyProvider;

MyProvider.propTypes = {
  children: propTypes.node.isRequired,
};
