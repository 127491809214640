import propTypes from 'prop-types';

interface IIconProps {
  className?: string;
}

function GithubIcon({ className }: IIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      className={className}
    >
      <g clipPath="url(#clip0_10_33)">
        <path
          fill="#B3B3B3"
          d="M15.241.783C7.257.783.783 7.256.783 15.241c0 6.388 4.143 11.807 9.887 13.719.723.134.988-.314.988-.696 0-.344-.013-1.483-.02-2.691-4.022.874-4.87-1.706-4.87-1.706-.658-1.671-1.606-2.116-1.606-2.116-1.311-.897.1-.879.1-.879 1.451.102 2.215 1.49 2.215 1.49 1.29 2.21 3.383 1.572 4.208 1.202.13-.934.504-1.572.918-1.933-3.212-.366-6.587-1.605-6.587-7.145 0-1.579.564-2.868 1.49-3.88-.15-.365-.646-1.836.14-3.827 0 0 1.213-.389 3.976 1.482 1.153-.32 2.39-.481 3.619-.487 1.229.006 2.466.167 3.622.487 2.76-1.87 3.972-1.482 3.972-1.482.787 1.991.292 3.462.142 3.826.927 1.013 1.487 2.302 1.487 3.88 0 5.554-3.382 6.777-6.601 7.134.518.45.98 1.329.98 2.678 0 1.934-.016 3.491-.016 3.967 0 .385.26.836.993.694 5.741-1.914 9.878-7.331 9.878-13.717 0-7.985-6.473-14.458-14.457-14.458zM6.198 21.38c-.032.071-.145.093-.248.044-.105-.048-.163-.145-.13-.218.032-.073.145-.094.25-.045.105.048.164.146.128.218zm.711.634c-.069.064-.204.034-.295-.067-.095-.1-.112-.235-.042-.3.07-.064.201-.034.296.067.095.102.113.235.041.3zm.488.812c-.088.062-.233.004-.323-.125-.088-.128-.088-.282.002-.344s.233-.007.323.121c.089.13.089.285-.002.348zm.825.94c-.079.088-.248.064-.371-.055-.127-.117-.162-.282-.082-.37.08-.087.25-.062.374.056.125.116.164.283.08.37zm1.067.318c-.035.113-.198.164-.362.116-.163-.05-.27-.182-.237-.296.034-.114.197-.168.362-.116.163.049.27.18.237.296zm1.213.134c.004.12-.135.219-.307.22-.172.005-.312-.092-.314-.21 0-.12.136-.218.309-.22.171-.004.312.092.312.21zm1.192-.045c.021.116-.099.235-.27.267-.167.031-.322-.04-.344-.156-.02-.12.101-.239.269-.27.17-.03.324.04.345.159z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_10_33">
          <path
            fill="#fff"
            d="M0 0h28.915v28.238H0z"
            transform="translate(.783 .783)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default GithubIcon;

GithubIcon.propTypes = {
  className: propTypes.string,
};
