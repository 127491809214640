import React, { useState } from 'react';
import { IoIosSend } from 'react-icons/io';
import Btn from '../Btn/Btn';
import { Input, Label, TextArea } from './style';
import { toast } from 'react-toastify';

function ContactForm() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // es-lint-disabled-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setName('');
      setEmail('');
      setMessage('');

      // Você pode adicionar lógica adicional aqui, como exibir uma mensagem de sucesso
      toast.info(
        'Currently, the contact service is offline. I am working to resolve this as quickly as possible. In the meantime, please reach out to me via social media.',
        {
          theme: 'dark',
          autoClose: 1000 + 50,
          closeOnClick: true,
        },
      );
    } catch (error) {
      console.error('Erro ao enviar a mensagem:', error);
      // Adicione lógica de tratamento de erro aqui, se necessário
    }
  };

  return (
    <div className="w-full min-[769px]:w-1/2">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col sm:flex-row gap-6 sm:gap-2 w-full">
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <Label className="poppins=bold text-white">YOUR NAME</Label>
              <Input
                type="text"
                placeholder="DIGIT YOUR NAME"
                className="poppins-bold"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col gap-2 w-full sm:w-1/2">
              <Label className="poppins=bold text-white">YOUR EMAIL</Label>
              <Input
                type="email"
                placeholder="DIGIT YOUR EMAIL"
                className="poppins-bold"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Label className="poppins=bold text-white">YOUR MESSAGE</Label>
            <TextArea
              placeholder="DIGIT YOUR MESSAGE"
              className="poppins-bold"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="w-1/2">
            <Btn variant="default" type="submit">
              SEND MESSAGE
              <IoIosSend className="icon-black" style={{ color: '#000' }} />
            </Btn>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
