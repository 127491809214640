import styled from 'styled-components';

export const TagArea = styled.li`
  padding: 0px 15px;
  border-radius: 20px;
  border: 2px solid #9c83ff;
  color: #9c83ff;
  position: relative;
  transition: all 1s;
`;
